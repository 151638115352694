import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { EventContext } from "../context/EventContext";
import {
  getBitmap,
  getPriceString,
  getEncodedProductStyle,
  getEncodedProductColor,
  capitalizeFirstLetter,
} from "../helpers";

const Product = ({ product }) => {
  const { eventID, customUnits, discountPercentage } = useContext(EventContext);
  const productStyle = getEncodedProductStyle(product);
  const productColor = getEncodedProductColor(product);

  if (!product) return null;

  const styleName = product.styleNameLong
    ? product.styleNameLong
    : product.styleCode;
  const colorName = product.colorNameLong
    ? product.colorNameLong
    : product.colorStory;
  const dimension = product.dimensionCode;

  const lastPrice =
    product.price - product.price * (parseFloat(discountPercentage) / 100);

  const str = customUnits
    ? product.price > 1
      ? `${product.price} ${customUnits}`
      : `${product.price} ${customUnits}`
    : getPriceString(product.price, customUnits);
       

  return (
    // Go to the product detail page for this product
    <Link
      to={`/${eventID}/store/product/${productStyle}/${productColor}`}
      className="storefrontProduct"
    >
      <div
        className="productImage skeleton"
        style={{ backgroundImage: `url(${getBitmap(product.image || product.imageAngles[0])})` }}
      ></div>
      <div className="productDetails skeleton">
        <span className="productName">
          {capitalizeFirstLetter(styleName)} {dimension}
        </span>
        <span className="productShortDesc">
          {capitalizeFirstLetter(colorName)}
        </span>
        {discountPercentage && discountPercentage > 0 ? (
          <div style={{ display: "flex", gap: "10px" }}>
            <span
              className="productPrice originalPrice"
              style={{ textDecoration: "line-through" }}
            >
              {getPriceString(
                product.price,
                (customUnits ? customUnits.toString() : "") || ""
              )}
            </span>
            <span className="productPrice discountedPrice">
              {getPriceString(
                lastPrice,
                (customUnits ? customUnits.toString() : "") || ""
              )}
            </span>
          </div>
        ) : (
          <span className="productPrice originalPrice">{str}</span>
        )}
      </div>
    </Link>
  );
};

export default Product;
