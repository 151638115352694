import React, { useEffect, useContext, useCallback, useRef, useState } from "react";

import PayPal from "./PayPal";
import Stripe from "./Stripe";
import { EventContext } from "../context/EventContext";
import { CartContext } from "../context/CartContext";
import alertImage from "../images/alert-octagon.png";

import { getPriceString } from "../helpers";
import { AuthContext } from "../context/AuthContext";

const CartSummary = ({ trigger, watch, user }) => {
  const {
    maxOverage,
    overBudgetRules,
    unlimitedOverage,
    unlimitedBudget,
    dropShipCharge,
    forceDropShip,
    discountPercentage,
    customUnits,
  } = useContext(EventContext);

  const {
    cartState,
    submitting,
    submitOrder,
    scrollToCart,
    scrollToAddress,
    scrollToFirstError,
    dropShip,
    setDropShip,
    overMax,
    setOverMax,
    overBudget,
    setOverBudget    
  } = useContext(CartContext);
  const { userData } = useContext(AuthContext);
  const counter = useRef(0);
  const name = `${watch("firstName")} ${watch("lastName")}`;
  
  const url = window.location.href;
  const parts = url.split("/");
  const idEvent = parts[parts.length - 2];
  const eventLocal = userData?.events?.find(
    (event) => event.eventId === idEvent
  );
  
  const lastBudget =
    eventLocal?.payed !== undefined
      ? Number(cartState?.budget) - eventLocal?.payed > 0
        ? Number(cartState?.budget) - eventLocal?.payed
        : 0
      : 0;  

  useEffect(() => {
    if (!cartState) return;
    const overage =
      cartState.total -
      Number(lastBudget) -
      cartState.payPalApplied +
      (dropShip ? dropShipCharge : 0);
    setOverBudget(
      cartState.total + (dropShip ? dropShipCharge : 0) >
        lastBudget + cartState.payPalApplied
    );
    const overageMatters =
      overBudgetRules === "paypal" ||
      overBudgetRules === "stripe" ||
      maxOverage >= 0;
    setOverMax(overage > 0 && overage > maxOverage && overageMatters);
  }, [
    overBudgetRules,
    cartState,
    maxOverage,
    setOverBudget,
    setOverMax,
    dropShip,
    dropShipCharge,
  ]);

  const getOverageMessage = useCallback(() => {
    const message = !!maxOverage
      ? overBudgetRules === "paypal"
        ? "Please settle your remainder with PayPal"
        : overBudgetRules === "stripe"
        ? "Please settle your remainder with a credit card"
        : overMax && !unlimitedOverage
        ? `You are over your max overage allowance of $${maxOverage}.`
        : "The balance will be billed to your member account."
      : "You are not allowed any overage on your budget. Please remove some items from your cart to stay within budget.";

    return message;
  }, [overMax, maxOverage, unlimitedOverage, overBudgetRules]);
 
  

  const renderClubPickupButton = useCallback(() => {
    return (
      <div>
        <button
          onClick={() => {
            scrollToAddress();
            setDropShip(false);
          }}
          style={{ background: !dropShip ? "green" : "gray" }}
          className="button"
        >
          {`Pickup at the club`}
        </button>
        
      </div>
    );
  }, [dropShip, scrollToAddress, setDropShip]);

  const renderDropShipButton = useCallback(() => {
    return (
      <div>
        <button
          onClick={() => {
            scrollToAddress();
            setDropShip(true);
          }}
          style={{ background: !!dropShip ? "green" : "gray" }}
          className="button"
        >
          {`Drop ship to your address (+$${dropShipCharge})`}
        </button>
        
      </div>
    );
  }, [dropShip, dropShipCharge, scrollToAddress, setDropShip ]);

  const renderSubmitButton = useCallback(() => {
    const emptyCart =
      !cartState.products || !Object.keys(cartState.products).length;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (user.firstName !== "" && user.lastName !== "") {
        counter.current = 0;
      }
      return;
    }, [user]);

    return (
      <div>
        <button
          onClick={() => {
            if(overBudgetRules === "stripe" & overBudget)return 
            if (counter.current > 0) return;
            counter.current++;
            trigger().then((verified) => {
              if (verified) {
                scrollToCart();
                setTimeout(() => submitOrder(), 700);
              } else {
                scrollToFirstError();
              }
            });
          }}
          disabled={emptyCart || (overBudgetRules === "paypal") & overBudget || (overBudgetRules === "stripe") & overBudget || maxOverage === 0 & overBudget }
          className="button"
        >
          {submitting ? "Submitting Order..." : "Submit order"}
        </button>
      </div>
    );
  }, [
    overMax,
    cartState.products,
    scrollToCart,
    scrollToFirstError,
    submitOrder,
    submitting,
    trigger,
  ]);

  const renderDeliveryOptionsMessage = useCallback(() => {
    const message = forceDropShip
      ? "For this event, the only delivery option is to drop ship to your address"
      : dropShipCharge < 0
      ? "For this event, the only delivery option is to pick up your items at the club."
      : "";
    return <em>{message}</em>;
  }, [forceDropShip, dropShipCharge]);

  // If there's only one delivery option available, be explicit
  const renderDeliveryOptions = useCallback(() => {
    return (
      <div>
        {/* <Arrow/> */}
        <h2>Delivery Options</h2>
        {!forceDropShip && renderClubPickupButton()}
        {dropShipCharge >= 0 && renderDropShipButton()}
        {renderDeliveryOptionsMessage()}
      </div>
    );
  }, [
    renderClubPickupButton,
    renderDropShipButton,
    renderDeliveryOptionsMessage,
    dropShipCharge,
    forceDropShip,
  ]);

  const renderBudgetSummary = useCallback(() => {
    const lastPrice =
      cartState.total -
      cartState.total * (parseFloat(discountPercentage) / 100);
    return (
      <div className="budget-summary">
        <h3 style={{ display: "flex", justifyContent: "space-between" }}>
          Subtotal{" "}
          {discountPercentage && discountPercentage > 0 ? (
            <div style={{ display: "flex", gap: "10px" }}>
              <span
                className="subtotal"
                style={{ textDecoration: "line-through" }}
              >
                {getPriceString(cartState.total, customUnits)}
              </span>
              <span className="subtotal">
                {getPriceString(lastPrice, customUnits)}
              </span>
            </div>
          ) : (
            <span className="subtotal">
              {getPriceString(cartState.total, customUnits)}
            </span>
          )}
        </h3>
        {!unlimitedBudget && (
          <h3 className={`Budget ${dropShip ? "" : "greyBorder"}`}>
            Budget{" "}
            <span className="subtotal">
              {getPriceString(Number(lastBudget), customUnits)}
            </span>
          </h3>
        )}
        {(unlimitedBudget || overBudgetRules === "paypal") && (
          <h3 className="greyBorder">
            Paid via PayPal{" "}
            <span className="subtotal">
              {getPriceString(cartState.payPalApplied)}
            </span>
          </h3>
        )}
        {dropShip && (
          <h3 className="greyBorder">
            Drop Ship Charge{" "}
            <span className="subtotal">{getPriceString(dropShipCharge)}</span>
          </h3>
        )}
      </div>
    );
  }, [
    Number(lastBudget),
    cartState.payPalApplied,
    cartState.total,
    dropShip,
    dropShipCharge,
    overBudgetRules,
    customUnits,
    unlimitedBudget,
  ]);

  const renderOverBudget = useCallback(() => {
    const message = getOverageMessage();
    return (
      <div>
        {!unlimitedBudget && (
          <div className="warning">
            <img
              src={alertImage}
              alt="Over budget"
              style={{ paddingBottom: "20px" }}
            />
            {message}
          </div>
        )}
        {overBudgetRules === "paypal" ? <PayPal watch={watch} /> : null}
        {overBudgetRules === "stripe" ? <Stripe watch={watch} name={name} /> : null}
      </div>
    );
  }, [overBudgetRules, getOverageMessage, unlimitedBudget, watch]);
  const lastPrice =
    cartState.total - cartState.total * (parseFloat(discountPercentage) / 100);

  const renderBalance = useCallback(() => {
    const subtotal =
      discountPercentage && discountPercentage > 0
        ? lastPrice - lastBudget
        : cartState.total - Number(lastBudget);

    // Add the drop ship charge if necessary
    const total = unlimitedBudget
      ? getPriceString(cartState.total, customUnits)
      : dropShip
      ? customUnits
        ? // If theres custom units and drop ship, only charge drop ship
          getPriceString(dropShipCharge)
        : // If using dollars, subtract the drop ship charge
          getPriceString(subtotal + dropShipCharge)
      : getPriceString(subtotal, customUnits);

    // Don't show the total if the order is ready for submission
    if (!overBudget || subtotal < 0)
      return <div className="note">Please submit your order</div>;

    return (
      <div>
        <h3>{unlimitedBudget || overBudget ? "Total" : "Remaining Balance"}</h3>
        <div
          className={
            overBudget && !unlimitedBudget
              ? "red balance balanceWarning"
              : "grey balance balanceWarning"
          }
        >
          {total}
        </div>
        {renderOverBudget()}
        <br />
      </div>
    );
  }, [
    renderOverBudget,
    cartState,
    dropShip,
    dropShipCharge,
    overBudget,
    customUnits,
    unlimitedBudget,
  ]);

  if (!cartState) return null;

  return (
    <React.Fragment>
      <h2>Summary</h2>
      {renderBudgetSummary()}
      {renderBalance()}
      {renderDeliveryOptions()}
      <br />
      {renderSubmitButton()}
    </React.Fragment>
  );
};

export default CartSummary;
