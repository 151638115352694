import React, { useState, useContext, useCallback, useMemo } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { CartContext } from "../context/CartContext";
import { ADD_PAYPAL } from "../reducers/CartReducer";
import { EventContext } from "../context/EventContext";
import { getStripeKeys, stripePayment } from "../firestore";
import { AuthContext } from "../context/AuthContext";
import {useFormContext} from "react-hook-form"

const PaymentForm = ({watch}) => {  
  // eslint-disable-next-line no-unused-vars
  const { cartState, cartDispatch, dropShip, submitOrder, payedStripe } = useContext(CartContext);
  const { customerID, eventID } = useContext(EventContext);
  const { user } = useContext(AuthContext);
  const stripe = useStripe();
  const elements = useElements();
  const {getValues} =  useFormContext()
  const [paymentFlag, setPaymentCall] = useState(false);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      try {
        const amountPaid = cartState.balance * -1;

        if (amountPaid <= 0)
          throw new Error("No need to pay if the balance is positive");
        else 
        if (amountPaid <= 0.5)
          throw new Error("Stripe minimum is $0.50 cent");
        event.preventDefault();

        /*need a paymentFlag to deal wit multiple clicks on the pay button*/
        if (!paymentFlag) {
          setPaymentCall(true);

          const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement),
          });
          
          if (error) {
            setPaymentCall(false);
            throw error;
          }
          
          const amount = Math.floor(amountPaid * 100);
          const firstName = watch("firstName")
          const lastName = watch("lastName")
          const email = user.email
          const name = `${firstName} ${lastName}`
          
          const response = await stripePayment({
            id: paymentMethod.id,
            customerID,
            amount: amount,
            name: name,
            email: email
          });
          
          const paymentID = paymentMethod.id
          
          const result = response.data;
            //COMMENT
          if (result.success) {
            console.log(`Paid ${amountPaid} with Stripe`);
            cartDispatch({ type: ADD_PAYPAL, amount: amountPaid });
            await payedStripe(firstName, lastName, amountPaid, email, eventID, paymentID)
            await submitOrder(getValues())
          } else {
            throw new Error(result.message);
          }
          setPaymentCall(false);
        }
      } catch (err) {
        console.error("Error with payment:", err);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      cartState.balance,
      cartDispatch,
      elements,
      stripe,
      setPaymentCall,
      paymentFlag,
    ]
  );

  const disable = !(
    watch("firstName")?.length > 0 &&
    watch("lastName")?.length > 0 &&
    watch("street")?.length > 0 &&
    watch("city")?.length > 0 &&
    watch("zip")?.length > 0
  );

  return (
    <form style={{ width: "100%", padding: "1px" }} onSubmit={handleSubmit}>
      <CardElement />
      <br />
      <button className="button" type="submit" disabled={!stripe || disable}>
        Pay
      </button>
      {disable && (
          <span
            style={{
              color: "red",
              fontWeigth: "800",
              fontSize: "16px",
              marginTop: "20px",
            }}
          >
            *Complete all shipping information.
          </span>
        )}
    </form>
  );
};

const Stripe = ({ watch }) => {
  
  const { customerID } = useContext(EventContext);
  const [stripePromise, setStripePromise] = useState();
  const pk = useMemo(async () => {
    if (!customerID) return;
    const result = await getStripeKeys({ customerID });
    const pk = result.data?.pk;
    setStripePromise(loadStripe(`${pk}`));
    return pk;
  }, [customerID]);
  


  if (!customerID || !pk || !stripePromise) return null;
  

  return (
    <Elements stripe={stripePromise}>
      <PaymentForm watch={watch}/>
    </Elements>
  );
};

export default Stripe;
